import * as React from 'react'
import cn from 'classnames'
import styles from './AvatarButtonGroup.module.scss'
import { Tag } from '../../atoms/Tag'
import { Tooltip } from '../../atoms/Tooltip'
import { IconButton } from '../../atoms/IconButton'
import { AvatarButton, AvatarButtonProps } from '../AvatarButton/AvatarButton'
import { Avatar } from '../../atoms/Avatar'
import { omit } from '../../../utils/omit'
import { UserCircleDashed, PlusCircle } from '@phosphor-icons/react'

export type AvatarButtonGroupProps = {
  permissions?: {
    allowAddUser?: boolean
  }
  eventHandlers?: {
    onClickAddUser?: () => void
  }
  avatars?: Array<
    Omit<AvatarButtonProps, 'size'> & {
      /**
       * Identifier for the avatar, used for consistent rendering of keys
       */
      identifier: string
    }
  >
  size?: AvatarButtonProps['size']
  avatarClassName?: string
  limit?: number
  limitClassName?: string
  limitStyle?: 'avatar' | 'tag'
  showEmptyState?: boolean
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>

/**
 * A group of avatar buttons with limit and overflow count
 */
export const AvatarButtonGroup: React.VFC<AvatarButtonGroupProps> = (props) => {
  const {
    avatarClassName,
    avatars,
    limit,
    size = 'xs' as const,
    eventHandlers,
    permissions,
    className,
    limitClassName,
    limitStyle = 'tag',
    showEmptyState = false,
    ...restProps
  } = props

  const truncatedAvatars = limit && avatars ? avatars.slice(limit) : []
  const overflowTooltipContent =
    limit &&
    avatars
      ?.slice(-(avatars.length - limit))
      ?.map((avatar) => avatar.tooltipContent)
      .join(', ')

  return (
    <div className={cn(styles.buttonGroup, className)} {...restProps}>
      {avatars?.length === 0 && showEmptyState && (
        <Tooltip content="No team members">
          <UserCircleDashed className="w-5 h-5 text-gray-200" />
        </Tooltip>
      )}
      {avatars?.map((avatar, index) => {
        if (limit && index >= limit) return null

        if (!avatar.onClick && !avatar.href) {
          return (
            <Tooltip
              content={avatar.tooltipContent}
              disabled={!avatar.tooltipContent}
              key={avatar.identifier}
            >
              <Avatar
                {...omit(avatar, 'tooltipContent')}
                className={avatarClassName}
                size={size}
              />
            </Tooltip>
          )
        }

        return (
          <AvatarButton
            className={avatarClassName}
            key={avatar.identifier}
            size={size}
            {...avatar}
          />
        )
      })}
      {permissions?.allowAddUser && (
        <IconButton
          className="z-10"
          title="Add a team member"
          onClick={(e) => {
            e.stopPropagation()
            eventHandlers?.onClickAddUser?.()
          }}
        >
          <PlusCircle aria-hidden />
        </IconButton>
      )}
      {truncatedAvatars.length > 0 && limitStyle === 'avatar' && (
        <AvatarLimit
          className={limitClassName}
          overflowNumber={truncatedAvatars.length}
          overflowTooltipContent={overflowTooltipContent}
          size={size}
        />
      )}
      {truncatedAvatars.length > 0 && limitStyle === 'tag' && (
        <TagLimit
          overflowNumber={truncatedAvatars.length}
          overflowTooltipContent={overflowTooltipContent}
        />
      )}
    </div>
  )
}

const AvatarLimit: React.VFC<{
  className?: string
  overflowNumber: number
  overflowTooltipContent?: string | 0
  size: AvatarButtonProps['size']
}> = (props) => {
  const { className, overflowNumber, overflowTooltipContent, size } = props

  return (
    <Tooltip
      className={cn('flex', size && styles[size])}
      content={overflowTooltipContent}
    >
      <Avatar
        className={className}
        fallbackText={`+${overflowNumber}`}
        size={size}
      />
    </Tooltip>
  )
}

const TagLimit: React.VFC<{
  overflowNumber: number
  overflowTooltipContent?: string | 0
}> = (props) => {
  const { overflowNumber, overflowTooltipContent } = props

  return (
    <Tooltip content={overflowTooltipContent}>
      <Tag variant="naked" className="ml-2">
        +{overflowNumber}
      </Tag>
    </Tooltip>
  )
}
